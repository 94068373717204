<template>
  <common-form
      ref="form"
      url="vendor"
      :edit="edit"
      :items="items"
      :beforeRequest="beforeRequest"
      @success="$emit('success')">
  </common-form>
</template>
<script>
export default {
  data() {
    return {
      edit: false,
      treeData: [],
      vendorData: []
    }
  },
  computed: {
    items() {
      return [
        {
              type: "tree-select",
              name: "上级账号",
              key: "parentId",
              treeData: this.vendorData
        },
        {
          type: "input",
          name: "登录账号",
          key: "account",
          required: true,
          min: 4,
          max: 50,
          disable: this.edit
        },
        {
          type: "password",
          name: "登录密码",
          key: "password",
          required: true,
          min: 6,
          max: 50,
        },
        {
          type: "input",
          name: "名称",
          key: "name",
          required: true,
        },
        // {
        //     type: "location",
        //     name: "公司地址",
        //     key: "location",
        // },
        {
          type: "input",
          name: "联系人",
          key: "contactPerson",
        },
        {
          type: "input",
          name: "联系电话",
          key: "contactNumber",
        },
        // {
        //     type: "tree",
        //     name: "权限选择",
        //     key: "menus",
        //     treeData: this.treeData
        // },
      ]
    }
  },
  methods: {
    show(model = {}) {
      model = JSON.parse(JSON.stringify(model))
      this.edit = model.id > 0
      if (this.edit) {
        model.password = "******"
        model.location = {
          address: model.address,
          latitude: model.latitude,
          longitude: model.longitude,
        }
      }
      model.menus = []
      this.$refs.form.show(model)

      this.$get('vendor/tree').then((r) => {
        this.vendorData = r.data

      })

      return

      this.$get('menu').then((r) => {
        this.treeData = r.data.rows.children

        if (model.id > 0) {
          this.$get('vendor/menu/' + model.id).then((r) => {
            model.menus = r.data
            this.$refs.form.show(model)
          })
        } else {
          model.menus = []
          this.$refs.form.show(model)
        }
      })
    },
    beforeRequest(model) {

      if (!model.location) {
        model.location = {}
      }
      return {
        ...model,
        menuId: model.menus.join(','),
        address: model.location.address,
        latitude: model.location.latitude,
        longitude: model.location.longitude,
      }
    }
  },
}
</script>
